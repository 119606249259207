import React from 'react';
import { graphql } from 'gatsby';

import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import Produktgruppen from '@mangoart/gatsby-ui/components/ezagrar/Produktgruppen';
import DefaultLayout from '../layout/DefaultLayout';

const ProduktePage = ({ data, location }) => {
  const { pageData, siteData, products, productgroups } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;
  const groupedProducts = {};

  products.edges.forEach(({ node }) => {
    if (node && node.productGroup && node.productGroup.id) {
      const productGroup = node.productGroup;

      groupedProducts[productGroup.id] = groupedProducts[productGroup.id] || [];
      groupedProducts[productGroup.id].push(node);
    }
  });

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>{content.title}</PageTitle>
      <Produktgruppen productGroups={productgroups} groupedProducts={groupedProducts} />
    </DefaultLayout>
  );
};

export default ProduktePage;

export const ProduktePageQuery = graphql`
  query ProduktePageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageProdukte {
      content {
        title
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
    productgroups: allCockpitProduktgruppe(filter: { published: { eq: true } }) {
      edges {
        node {
          id
          name
        }
      }
    }
    products: allCockpitProdukte(sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          name
          link
          models
          image {
            id
            childImageSharp {
              gatsbyImageData(
                width: 340
                aspectRatio: 1.5
                transformOptions: { fit: CONTAIN }
                backgroundColor: "#fff"
                quality: 90
              )
            }
          }
          productGroup {
            id
          }
        }
      }
    }
  }
`;
