import React from 'react';
import classNames from 'classnames';

import * as styles from './Button.module.css';
import { Icon } from './Icon';
import { Link } from 'gatsby';

export const Button = ({ to, extraPath, color, icon, children, style, onClick }) => {
  const isAbsolute = to && to.indexOf('://') !== -1;
  const colorClass = color || '';

  const classes = classNames(styles.button, styles[colorClass]);

  return isAbsolute ? (
    <a className={classes} href={to} target="_blank" style={style} onClick={onClick}>
      {children}
      <Icon icon={icon} />
    </a>
  ) : (
    <Link className={classes} to={to} style={style} onClick={onClick}>
      {children}
      {<Icon icon={icon} />}
    </Link>
  );
};
