import React from 'react';
import classNames from 'classnames';

import ProductEntry from './ProductEntry';

import * as styles from './Produktgruppen.module.css';
import { PageTitle } from './PageTitle';

const sortHerstellerFunction = (a, b) => {
  if (a.starred && b.starred) {
    return a.order - b.order;
  } else if (a.starred) {
    return -1;
  } else if (b.starred) {
    return 1;
  }
  return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
};

const renderProduct = (data) => {
  return data && data.published !== false ? <ProductEntry product={data} key={data.id} cols={4} /> : null;
};

function Produktgruppen({ productGroups, groupedProducts }) {
  return (
    <section className="defaultWidth" style={{ marginBottom: -8 }}>
      {productGroups.edges.map(({ node }) => {
        if (groupedProducts[node.id]) {
          return (
            <React.Fragment key={node.id}>
              <PageTitle>{node.name}</PageTitle>
              <div className={classNames(styles.content)}>
                <span id={node.name} className={styles.anchor} />
                {groupedProducts[node.id].map(renderProduct)}
              </div>
            </React.Fragment>
          );
        } else {
          return <></>;
        }
      })}
    </section>
  );
}

export default Produktgruppen;
