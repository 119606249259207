import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import * as styles from './ProductEntry.module.css';
import { Button } from './Button';

export default function ProductEntry({ product, cols }) {
  if (!product) {
    return null;
  }
  const { id, name, image, models, link } = product;

  const valid = image && name;

  const navigateToExternalPage = (event) => {
    try {
      event.stopPropagation();
      event.preventDefault();
      // eslint-disable-next-line no-undef
      const win = window.open(link, '_blank');
      win.focus();
    } catch (e) {}
  };

  const classes = classNames(styles.content, styles[`box1Of${cols || 4}`]);

  if (valid) {
    return (
      <div className={classes} onClick={navigateToExternalPage} role="presentation" key={id}>
        <div className={styles.top}>
          <GatsbyImage image={getImage(image)} imgStyle={{ width: '100%', objectFit: 'contain' }} alt={name} />
        </div>
        <div className={styles.actionContainer}>
          <div className={styles.action}>
            {name}
            <ul>{models && models.map((models) => <li>{models}</li>)}</ul>
            <Button to={link} icon="arrow-right">
              Mehr Infos
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
